import React from 'react';
import {AlertTitle} from '@mui/material';
import Alert from '@mui/material/Alert';

export default function NotFound() {
  return (
    <>
      <Alert severity="error">
        <AlertTitle>NOT FOUND</AlertTitle>
        요청하신 페이지를 찾을 수 없습니다
      </Alert>
    </>
  );
}
